import React from "react"
import { Helmet } from "react-helmet"
import Layout from "./layout"
import { graphql } from "gatsby"
import get from "lodash/get"

class MarkdownPage extends React.Component {
  render() {
    const post = get(this, "props.data.post")
    const site = get(this, "props.data.site")
    const title = get(post, "frontmatter.title")
    const siteTitle = get(site, "meta.title")

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={`${title} | ${siteTitle}`}
          meta={[
            { property: "og:title", content: get(post, "frontmatter.title") },
            { property: "og:type", content: "article" },
            {
              property: "og:description",
              content: get(post, "excerpt"),
            },
            {
              property: "og:url",
              content: get(site, "meta.url") + get(post, "frontmatter.path"),
            },
          ]}
        />
        <div className="page-container" style={{margin: '2em auto', maxWidth:'40em', textAlign:'justify'}}>
        <h1 className="page-title" style={{marginBottom:'.75em'}}>{title}</h1>
        <div className="page-content" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </Layout>
    )
  }
}

export default MarkdownPage

export const pageQuery = graphql`
  query singleMarkdownPages($slug: String!, $language: String!) {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
    post: markdownRemark(
      fields: { slug: { eq: $slug }, language: { eq: $language } }
    ) {
      id
      html
      excerpt
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
      }
    }
  }
`
